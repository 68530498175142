import React, { Fragment, useEffect,useRef , useState } from 'react'
import { Col, Form, Row , Popover,Overlay, OverlayTrigger, ButtonToolbar } from 'react-bootstrap'
import 'react-quill/dist/quill.snow.css'; // Estilos del editor
import { useFetchGet } from '../../hooks/useFetchGet';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { Editor } from "@tinymce/tinymce-react";


export default function FormsAR({
    tipo,
    dataAR,
    setDataAR,
    momentosEvaluativos,
}) {

    
    const [cuestionarios]=useFetchGet("cuestionariosLMS")
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const popover = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Información</Popover.Header>
          <Popover.Body>
            Los momentos evaluativos se configuran en la sección de las asignaturas del programa.
          </Popover.Body>
        </Popover>
      );

    const handleClick = (e) => {
        setShow(!show); 
        target.current = e.target;
    };


  return (
    <>
    {tipo.tipo=="Actividad" &&
    <>
            <Row>
            <Col>
                <Form.Group className="mb-3">
                <Form.Label>Fecha de inicio</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Fecha de inicio"
                    value={dataAR.fecha_inicio}
                    onChange={(e)=>{setDataAR({...dataAR,fecha_inicio:e.target.value})}}
                />
                </Form.Group>
            </Col>
            <Col>
                <Form.Group className="mb-3">
                <Form.Label>Fecha de finalización</Form.Label>
                <Form.Control
                    type="date"
                    placeholder="Fecha de finalización"
                    value={dataAR.fecha_fin}
                    onChange={(e)=>{setDataAR({...dataAR,fecha_fin:e.target.value})}}
                />
                </Form.Group>
            </Col>
        </Row>
        <Form.Group className="mb-3">
                <Form.Label>Tendra calificación?</Form.Label>
                <Form.Check
                    type="checkbox"
                    label="Si"
                    checked={dataAR.RequiereCalificacion==1?true:false}
                    onChange={(e)=>{setDataAR({...dataAR,RequiereCalificacion:e.target.checked})}}
                />
        </Form.Group>
        <Row>
            {dataAR.RequiereCalificacion==1 &&
            <>
            <Col>            
                <Form.Group className="mb-3">
                <Form.Label>Momento evaluativo
                    <span 
                        ref={target} 
                        onClick={handleClick} 
                        style={{ 
                            cursor: 'pointer', 
                            display: 'inline-flex',
                             alignItems: 'center', 
                             justifyContent: 'center', 
                             height: '20px', 
                             width: '20px',
                             borderRadius: '50%',
                             border: '1px solid #ccc',
                             marginLeft: '5px',
                             backgroundColor: '#f8f9fa',
                            }}
                    >
                        ?
                    </span>

                    <Overlay
                        show={show}
                        target={target.current}
                        placement="bottom"
                        container={target.current}
                        containerPadding={20}
                        rootClose
                        onHide={() => setShow(false)} 
                    >
                        {popover}
                    </Overlay>
                </Form.Label> 
                <Form.Select
                    value={dataAR.id_nota_asignatura}
                    onChange={(e)=>{setDataAR({...dataAR,id_nota_asignatura:e.target.value})}}
                >
                    <option value="">Seleccionar</option>
                    {momentosEvaluativos.map((momento)=>{
                        return <option key={momento.IdNotaAsignatura} value={momento.IdNotaAsignatura}>{momento.Nombre}</option>
                    })}
                </Form.Select>
                </Form.Group>

            </Col>
            <Col>
                <Form.Group className="mb-3">
                    <Form.Label>Número de intentos</Form.Label>
                    <Form.Control
                        type="number"
                        value={dataAR.numero_intentos}
                        onChange={(e)=>{setDataAR({...dataAR,numero_intentos:e.target.value})}}
                    />
                </Form.Group>
            </Col>
            {tipo.id==6 &&
            <Col>
                {/*tiempo el cuestionario */}
                <Form.Group className="mb-3">
                <Form.Label>Minutos para contestar</Form.Label>
                <Form.Control
                    type="number"
                    value={dataAR.minutos_contestar}
                    onChange={(e)=>{setDataAR({...dataAR,minutos_contestar:e.target.value})}}
                />
                </Form.Group>
            </Col>
            }
            </>
        }
        </Row>
        </>
    }
    <Form.Group className="mb-3">
      <Form.Label>Nombre</Form.Label>
      <Form.Control
        type="text"
        value={dataAR.nombre}
        onChange={(e)=>{setDataAR({...dataAR,nombre:e.target.value})}}
      />
    </Form.Group>
    <Form.Group className="mb-3">
      <Form.Label>Descripción</Form.Label>
      <Form.Control
        as="textarea"
        placeholder="Descripción"
        value={dataAR.descripcion}
        onChange={(e)=>{setDataAR({...dataAR,descripcion:e.target.value})}}
      />
    </Form.Group>
    {tipo.id==1 &&
    <Form.Group className="mb-3">
      <Form.Label>Enlace</Form.Label>
      <Form.Control
        type="text"
            placeholder="Enlace de youtube"
            value={dataAR.url}
            onChange={(e)=>{setDataAR({...dataAR,url:e.target.value})}}
        />
        </Form.Group>
    }
    {tipo.id==2 &&
    <Form.Group className="mb-3">
      <Form.Label>Enlace</Form.Label>
      <Form.Control
        type="text"
            placeholder="Enlace"
            value={dataAR.url}
            onChange={(e)=>{setDataAR({...dataAR,url:e.target.value})}}
        />
        </Form.Group>
    }

    {tipo.id==3 &&
        <Form.Group className="mb-3">
            {/* subir archivo */}
            <Form.Label>Archivo</Form.Label>
            <Form.Control
            type="file"
            placeholder="Archivo"
            onChange={(e)=>{setDataAR({...dataAR,archivo:e.target.files[0]})}}
            />
        </Form.Group>
    }
    {tipo.id==4 &&
        <div>

        <Editor
            apiKey='zrwvml54fz7xit4hcss2vdkulw4ynby8qz5kqtozfanuhqef'
            init={{
            plugins: ['lists', 'link', 'image', 'media', 'table', 'code'],
            toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright | code',
            menubar: false,
            }}
            value={dataAR.contenido}
            onEditorChange={(content) => setDataAR({ ...dataAR, contenido: content })}
        />
    </div>
    }
    {/* mostrar los custionarios diponibles  */}
    {tipo.id==6 &&
  
    <Form.Group className="mb-3">
            {console.log(cuestionarios)}  
    <Form.Label>Cuestionario</Form.Label>
    <Form.Select
        value={dataAR?.id_actividad}
        onChange={(e)=>{setDataAR({...dataAR,id_actividad:e.target.value})}}
    >
        <option value="">Seleccione un cuestionario</option>
        {cuestionarios.map((c)=>{
            return <option value={c?.id}>{c?.titulo}</option>
        })}
    </Form.Select>
    </Form.Group>
    }
    </>
  )
}
